import React, { lazy } from "react";

import Loadable from "../components/loader/Loadable";
import LeagueLayout from "../layout/LeagueLayout";

const Home = Loadable(lazy(() => import("../pages/session_leguage/Home")));
const Myfantasy = Loadable(
  lazy(() => import("../pages/session_leguage/Myfantasy"))
);
const Booster = Loadable(
  lazy(() => import("../pages/session_leguage/Booster"))
);

const LeagueRoutes = {
  path: "/",
  element: <LeagueLayout />,
  children: [
    {
      path: "/league-home",
      element: <Home />,
    },
    {
      path: "/league-booster",
      element: <Booster />,
    },
    {
      path: "/Myfantasy",
      element: <Myfantasy />,
    },
  ],
};

export default LeagueRoutes;
