import Session from "../../utils/session";
import { ALL_PLAYER_LIST, ALL_PLAYER_LIST_CRICKET, ALL_PLAYER_LIST_SOCCER, ALL_PLAYER_LIST_SOCCER_EDIT, EDIT_SOCCER_TEAM, PLAYER_DEC, PLAYER_INC, PLAYER_ROLE, RESET_PLAYER, SAVE_TEAM_IN_SESSION, SELECT_CAPTAIN, SELECT_VISE_CAPTAIN, TEAM_SELECTED_TAB, TEAM_TYPE } from "../constants/soccer";



const Roles = [
    { role: 'Goalkeeper', min: 1, max: 1, selected: 0, remain: 1 },
    { role: 'Defender', min: 3, max: 5, selected: 0, remain: 3 },
    { role: 'Midfielder', min: 3, max: 5, selected: 0, remain: 3 },
    { role: 'Forward', min: 1, max: 3, selected: 0, remain: 1 },
]

// console.log('strorage team data==>>>>>', Session.getSession('team_data'));

const team_data = Session.getSession('team_data')



let initialState = {
    teamType: team_data?.teamType || '',
    playerList: team_data?.playerList || [],
    selected_player_count: team_data?.selected_player_count || 0,
    playerRoles: team_data?.playerRoles || [],
    team_selected_tab: team_data?.team_selected_tab || 'wk-tab',
    playerTeamCount: team_data?.playerTeamCount || 0,

};





const CreateTeamReducer = function (state = initialState, action) {
  
    const setLocalTeamData = () => {
        setTimeout(() => {
            Session.setSession('team_data', {
                teamType: state?.teamType,
                playerList: state?.playerList,
                selected_player_count: state?.selected_player_count + 1,
                playerRoles: state?.playerRoles,
                team_selected_tab: state?.team_selected_tab,
                playerTeamCount: state?.playerTeamCount
            })
        }, 1000);
    }
    try {
        switch (action.type) {

            case ALL_PLAYER_LIST_SOCCER:
                Session.clearSession('team_data')
                return {
                    ...state,
                    playerTeamCount: action.payload?.playerTeamCount,
                    selected_player_count: 0,
                    playerList: action.payload?.results?.map(player => {
                        player.isSelected = false
                        player.isCaptain = false
                        player.isViceCaptain = false
                        return player
                    }),
                    playerRoles: JSON.parse(JSON.stringify(Roles)),
                    team_selected_tab: 'wk-tab',
                };

            case EDIT_SOCCER_TEAM:

                const playerData = action?.payload
                const { total_goalkeeper, total_defender, total_midfielder, total_forward } = playerData
                const playerIds = playerData?.seriesPlayer
                const captain_player_id = playerData?.captain_player_id
                const vice_captain_player_id = playerData?.vice_captain_player_id
                setLocalTeamData()
                return {
                    ...state,
                    selected_player_count: 11,
                    playerList: state?.playerList?.map(player => {

                        if (playerIds?.includes(player?.player_id)) {
                            player.isSelected = true
                        }
                        if (captain_player_id == player?.player_id) {
                            player.isCaptain = true
                        }
                        if (vice_captain_player_id == player?.player_id) {
                            player.isViceCaptain = true
                        }

                        return player
                    }),
                    playerRoles: [
                        { role: 'Goalkeeper', min: 1, max: 1, selected: total_goalkeeper, remain: 0 },
                        { role: 'Defender', min: 3, max: 5, selected: total_defender, remain: 0 },
                        { role: 'Midfielder', min: 3, max: 5, selected: total_midfielder, remain: 0 },
                        { role: 'Forward', min: 1, max: 3, selected: total_forward, remain: 0 },
                    ]
                };

            case PLAYER_INC:
                var item = action?.payload;

                if (state?.selectedPlayerCount !== 11) {
                    const newPlayers = state?.playerList.filter(player => {
                        if (player.player_id === item?.player_id) {
                            player.isSelected = true
                        }
                        return player;
                    })

                    var playerRoles = state?.playerRoles.map(ele => {
                        if (ele.role === item?.player_role) {
                            ele.selected += 1
                            if (ele.remain != 0) {
                                ele.remain -= 1
                            }
                        }
                        return ele
                    })
                    setLocalTeamData()

                    return {
                        ...state,
                        selected_player_count: state?.selected_player_count + 1,
                        playerRoles: playerRoles,
                        playerList: newPlayers

                    };

                } else {
                    return {
                        ...state,
                    };
                }

            case PLAYER_DEC:
                var item = action?.payload;


                const newPlayers = state?.playerList.filter(player => {
                    if (player.player_id === item?.player_id) {
                        player.isSelected = false
                    }
                    return player;
                })

                var playerRoles = state?.playerRoles.map(ele => {
                    if (ele.role === item?.player_role) {
                        if (ele.selected === ele.min) {
                            ele.remain += 1
                        }
                        ele.selected -= 1
                    }
                    return ele
                })
                setLocalTeamData()
                return {
                    ...state,
                    selected_player_count: state?.selected_player_count - 1,
                    playerList: newPlayers,
                    playerRoles: playerRoles,
                };


            case RESET_PLAYER:
                setLocalTeamData()
                return {
                    ...state,
                    selected_player_count: 0,
                    playerRoles: JSON.parse(JSON.stringify(Roles)),
                    playerList: state?.playerList?.map(player => {
                        player.isSelected = false
                        player.isCaptain = false
                        player.isViceCaptain = false
                        return player
                    })
                };


            case SELECT_CAPTAIN:
                const captainId = action?.payload;

                const newPlayerCaptain = state?.playerList?.map((player, i) => {
                    if (captainId === player?.player_id && player.isViceCaptain === true) {
                        player.isViceCaptain = false
                    }

                    if (captainId === player?.player_id) {
                        player.isCaptain = !player?.isCaptain
                    } else {
                        player.isCaptain = false
                    }
                    return player;
                })

                return {
                    ...state,
                    playerList: newPlayerCaptain

                };

            case SELECT_VISE_CAPTAIN:
                const viseCaptainId = action?.payload;

                const newPlayerViseCaptain = state?.playerList?.map((player, i) => {
                    if (viseCaptainId === player?.player_id && player.isCaptain === true) {
                        player.isCaptain = false
                    }
                    if (viseCaptainId === player?.player_id) {
                        player.isViceCaptain = !player?.isViceCaptain
                    } else {
                        player.isViceCaptain = false
                    }

                    return player;
                })

                return {
                    ...state,
                    playerList: newPlayerViseCaptain
                };

            case TEAM_SELECTED_TAB:
                return {
                    ...state,
                    team_selected_tab: action?.payload
                };

            case TEAM_TYPE:
                return {
                    ...state,
                    teamType: action?.payload
                };
            // case SAVE_TEAM_IN_SESSION:
            //     Session.setSession('team_data',)
            //     return {
            //         ...state,
            //     };




            default:
                return state;
        }
    } catch (error) {
        console.log('err in create team soccer reducer==>>>>', error)
    }
};

export default CreateTeamReducer;



