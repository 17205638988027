import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import BottomMenu from '../components/BottomMenu'

const CommonBottom = () => {
    const location = useLocation()

    var noBottomMenu = [ "/AllContest", "/addcash", "/contest-details", "/mycontest", "/player-info", "/transaction", "/withdrawamount", "/rewards", "/howtoplay", "/terms-condition",
     "/privacypolicy" ,"/legality", "/faqs", "/createcontest","/prize-breakup", "/create-team", '/save-team', "/my-profile", "/verifykyc", "/fantasypoint", "/team-preview", "/select-team", "/update-team",
      "/notifications" ,'/profile', "/share-contest", 
      //soccer routes=>>>>>>>>>>>
      "/all-contest-soccer", '/soccer-contest-details','/soccer-select-team', '/soccer-create-team', '/soccer-save-team', "/soccer-team-preview", "/socceer-create-contest","/soccer-prize-breakup",
      "/player-info-soccer","/mycontest-soccer", "/transaction", "/notifications"
     ];




    //  var isBottom = [ "/contest-details",  "/player-info", "/transaction", "/withdrawamount", "/rewards", "/howtoplay", "/terms-condition",
    //  "/privacypolicy" ,"/legality", "/faqs", "/createcontest","/prize-breakup", "/create-team", '/save-team', "/my-profile", "/verifykyc", "/fantasypoint", "/team-preview", "/select-team", "/update-team", ];

     var noBottomAndButton = ["/contest-details", "/mycontest", "/privacypolicy", "/fantasypoint", "/verifykyc", '/soccer-contest-details',"/mycontest-soccer", "/transaction",  "/notifications",
      "/player-info-soccer", "/share-contest", ]


     var checkBottom = noBottomMenu.includes(location?.pathname);
     var noButton = noBottomAndButton.includes(location?.pathname);
    

    useEffect(() => {
      if(checkBottom){
        if(noButton){
          document.getElementById("outlet-container")?.classList?.add("bottom-button-false");
          document.getElementById("outlet-container")?.classList?.remove("bottom-button-true");
        }else{
          document.getElementById("outlet-container")?.classList?.add("bottom-button-true");
          document.getElementById("outlet-container")?.classList?.remove("bottom-button-false");
        }
      }else{
        document.getElementById("outlet-container")?.classList?.remove("bottom-button-true");
        document.getElementById("outlet-container")?.classList?.remove("bottom-button-false");
      }
     
    }, [location?.pathname])

    // useEffect(() => {
    //   if (checkBottom) {
    //     document.getElementById("outlet-container")?.classList?.add("full-width-outlet");
    //     document.getElementById("outlet-container")?.classList?.remove("no-bottom-outlet");
    //   } else if (noBottom) {
    //     // document.getElementById("outlet-container")?.classList?.remove("full-width-outlet");
    //     // document.getElementById("outlet-container")?.classList?.add("no-bottom-outlet");
    //   } else {
    //     document.getElementById("outlet-container")?.classList?.remove("full-width-outlet");
    //     document.getElementById("outlet-container")?.classList?.remove("no-bottom-outlet");
    //   }
  
    // }, [location?.pathname])

    // console.log(noBottomMenu.includes(location?.pathname) ? 'no bottom menu' : 'bottom true');
    


  return (
    <>
    {
        noBottomMenu.includes(location?.pathname) ?  <></> : <BottomMenu/> 
    }
  
    </>
  )
}

export default CommonBottom