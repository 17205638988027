

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { HashLink } from 'react-router-hash-link';

import { logout } from "../redux/actions/AuthActions";
import Session from "../utils/session";

function Header() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user, setUser] = useState(Session.getSession('user'))
  const [path, setPath] = useState(location?.pathname.includes('/play-rummy'))




  const logoutUser = async () => {
    const result = await dispatch(logout())
    if (result?.success) {
      setUser(null)
      navigate('/')
    }
  }

  return (
    <>
      <header style={{ position: path ? 'initial' : 'absolute',  }}>
        <Container >
          <Row className="align-items-center">
            <Col md={4} xs={6}>
              <div className="logo">
                <Link to="/">
                  <img src="../images/logo.svg" alt="" />
                </Link>
              </div>
            </Col>
            <Col
              md={8} xs={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Navbar expand="md" className="me-md-4 me-2">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Link to='/home'>
                      <Nav.Link href="/home">Home</Nav.Link>
                    </Link>
                    <Link to='/Fantasy'>
                      <Nav.Link href="/Fantasy">Our Games</Nav.Link>
                    </Link>
                    {/* <Link to='/Fantasy'> */}
                      
                      <HashLink to="/home#FAQ">
                      <Nav.Link href="3">FAQ</Nav.Link>
                      </HashLink>
                      

                    {/* </Link> */}
                    <Link to='/Fantasy'>
                      <Nav.Link href="#Download">Download App</Nav.Link>

                    </Link>
                    <Link to='/MyOffer'>
                      <Nav.Link href="/MyOffer">Offers</Nav.Link>
                    </Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              {
                user ? (
                  <a href="#" className="btn py-2" onClick={logoutUser}>
                    Logout
                  </a>
                ) : (
                  <a href="#" className="btn py-2" onClick={() => navigate('/signin')}>
                    Login
                  </a>
                )
              }

            </Col>
          </Row>
        </Container>
      </header>
    </>

  );
}

export default Header;


