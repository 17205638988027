import { React, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationPage, readNotification } from "../redux/actions/OtherActions";
import { get_profile } from "../redux/actions/AuthActions";
import io from "socket.io-client";




function Notification() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useSelector(state => state.auth)
  const { unread_msg } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(getNotificationPage())
  }, [])

  const clickNotification = () => {
    dispatch(readNotification())
    setTimeout(() => {
      dispatch(get_profile())
    }, 500);
  }

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);

    socket.off('notification_count').on('notification_count', (data) => {
      console.log('notification socket called', data);
      dispatch(get_profile())
    })
  }, [])

  return (
    <>
      {
        location?.pathname !== '/notifications' && <Link to="/notifications" onClick={clickNotification} className="notification ms-3 position-relative">
          <img src="./images/notification.png" alt="" />
          {
            unread_msg > 0 && <span className="position-absolute">{unread_msg}</span>
          }

        </Link>
      }

    </>
  );
}

export default Notification;