import React from "react";
import {
  Link,
  useNavigate,
  Outlet,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import CommonHeader from "../../../layout/CommonHeader";

const Header = () => {
  return (
    <>
      <div className="top_header px-4">
        <div className="back_arrow cursor">
          <div className="d-flex align-items-center">
            <div>
              <img src="./images/top_arrow.png" className="me-3" alt="" />
              Season League 2023
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
