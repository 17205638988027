import { useEffect, lazy } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import Loadable from '../components/loader/Loadable'
import BaseLayout from '../layout/BaseLayout'
import Session from '../utils/session'

const ResponsiblePlay = Loadable(lazy(() => import('../pages/other/WebResponsiblePlay')))

// import component
const Signin = Loadable(lazy(() => import('../pages/login/Signin')))
const Otp = Loadable(lazy(() => import('../pages/login/Otp')))
const Welcome = Loadable(lazy(() => import('../pages/userWallet/Welcome')))

const Auth = Session.getSession('token') || false
//= ====>>>>>>>not found page
const NotFound = ({ to }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(to)
  }, [])
}

const HomeRedirect = ({ to }) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    navigate(to)
  }, [])

  if (!Auth) {
    location?.pathname === '/' && navigate('/home')
  }
}

const MainRoutes = {

  // All common routes
  path: '/',
  element: <BaseLayout />,
  children: [
    {
      path: '/',
      element: <HomeRedirect to='/home' />
    },
    {
      path: '/signin',
      element: <Signin />
    },
    {
      path: '/Otp',
      element: <Otp />
    },
    {
      path: '/profile',
      element: <Welcome />
    },
    {
      path: '/responsiblePlay',
      element: <ResponsiblePlay />
    },
    {
      path: '*',
      element: <NotFound to='/signin' />
    }
  ]
}

export default MainRoutes
