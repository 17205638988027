import React from "react";

const Footer = () => {
  return (
    <div>
      <div class="bottom_menu menu_tabs_fx league_footer">
        <ul>
          <li class="cursor text-yellow">
            <a class="active">
              <img class="active" src="./images/home_active.png" alt="" /> Home
            </a>
          </li>
          <li class="cursor text-light-blue offer_center">
            <a class="">
              <img class="active" src="./images/league/contest.svg" alt="" />
              Offers
            </a>
          </li>

          <li class="cursor text-light-red">
            <a class="">
              <img class="active" src="./images/wallet_active.png" alt="" />
              Wallet
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
