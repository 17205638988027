
import Session from "../../utils/session";
import { GET_STATIC_PAGE, GET_NOTIFICATION_PAGE, SET_REMINDER, TEAM_PREVIEW, GET_NOTIFICATION_SCROLL_DATA } from "../constants/Other";
import { CREATE_TRANS_ID } from "../constants/Payment";


let initialState = {
    staticPage: {},
    notificationList: [],
    totalDocs: '',
    isOpen:false,
    teamPreview:false,
};



const OtherReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case GET_STATIC_PAGE:
                return {
                    ...state,
                    staticPage: action.payload
                };

            case GET_NOTIFICATION_PAGE:
                return {
                    ...state,
                    // notificationList: action.payload
                    totalDocs: action.payload?.results?.totalDocs,
                    notificationList : action?.payload?.results?.docs
                };
            case GET_NOTIFICATION_SCROLL_DATA:
               
                return {
                    ...state,
                    totalDocs: action.payload?.results?.totalDocs,
                    notificationList : [...state.notificationList, ...action?.payload?.results?.docs]
                };
                case SET_REMINDER:
                return {
                    ...state,
                    isOpen: action.payload
                };
                case TEAM_PREVIEW:
                return {
                    ...state,
                    teamPreview: action.payload
                };
              
            default:
                return state;
        }
    } catch (error) {
      //console.log('err in payment reducers==>>>>', error)
    }
};

export default OtherReducer;