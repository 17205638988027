// material-ui
// import LinearProgress from '@mui/material/LinearProgress';
// import { styled } from '@mui/material/styles';

import ProgressBar from 'react-bootstrap/ProgressBar';

// styles
// const LoaderWrapper = styled('div')({
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     zIndex: 1301,
//     width: '100%'
// });

var styles = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
}

// ==============================|| LOADER ||============================== //
const Loader = () => (
    <div style={styles}>
        <ProgressBar now={60} />
    </div >
);

export default Loader;
