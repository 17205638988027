import { lazy } from 'react'
import Loadable from '../components/loader/Loadable'
import BaseLayout from '../layout/BaseLayout'
import WebAboutus from '../pages/other/WebAboutus'

// import component
const Home = Loadable(lazy(() => import('../pages/home/Home')))

// static pages
const WebResponsiblePlay = Loadable(lazy(() => import('../pages/other/WebResponsiblePlay')))
const WebPrivacyPolicy = Loadable(lazy(() => import('../pages/other/WebPrivacyPolicy')))
const WebTernCondition = Loadable(lazy(() => import('../pages/other/WebTernCondition')))

//= ====>>>>>>>not found page
// const NotFound = ({ to }) => {
//   const navigate = useNavigate()

//   useEffect(() => {
//     navigate(to)
//   }, [])
// }

// All common routes
const CommonRoutes = {
  path: '/',
  element: <BaseLayout />,
  children: [
    {
      path: '/home',
      element: <Home />
    },
    {
      path: '/web-responsible-play',
      element: <WebResponsiblePlay />
    },
    {
      path: '/web-privacy-policy',
      element: <WebPrivacyPolicy />
    },
    {
      path: '/about-us',
      element: <WebAboutus />
    },
    {
      path: '/web-terms-condition',
      element: <WebTernCondition />
    }

  ]
}

export default CommonRoutes
