import Slider from "react-slick";
import { Row, Container, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useSelector } from "react-redux";
import Session from "../utils/session";


function Footer() {

  const user = Session.getSession('user')
  console.log('user log==>>>>>>>>',user);


  return (
    <>
      <footer>
        {/* <div className="offers">
          <Container>
            <div className="heading">
              <h2>Offers</h2>
              <p>Get 200% Bonus on upto Rs. 1000</p>
            </div>

            <Row className="justify-content-center">
              <Col md={4} sm={6}>
                <div className="offers_main text-center">
                  <img src="../images/refer_earn.png" />
                 
                  <h3>Refer & Earn</h3>
                </div>
              </Col>
              <Col md={4} sm={6}>
                <div className="offers_main text-center">
                  <img src="../images/welcome_bonus.png" />
                  <h3>Welcome Bonus</h3>
                </div>
              </Col>
              <Col md={4} sm={6}>
                <div className="offers_main text-center">
                  <img src="../images/tournament.png" />
                  <h3>Tournament</h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        <div className="footer">
          <Container>

            <Row>
              <Col md={4}>
                <div className="footer_logo mb-4">
                  <a href="#">
                    <img src="../images/logo.svg" />
                  </a>

                </div>
                <h4>About Ultrafair</h4>
                <p>Lorem Ipsum is simply dummy text of the 
                printing and typesetting industry. Lorem 
                Ipsum has been the industry's standard 
                dummy text ever since the 1500s, when an 
                unknown </p>
              </Col>
 
              <Col md={8}>
                <Row>

                <Col xl={3} sm={6}>
                <div className="footer_nav">
                  <h4>Games</h4>  
                  <ul>
                    <li>
                      <Link to={user ? '/Fantasy' : '/login'}>Cricket</Link>
                    </li>

                    <li>
                      <Link to={user ? '/Fantasy' : '/login'}>Football</Link>
                    </li>
                    <li>
                      <HashLink to={user ? '/Fantasy' : '/login'}>f1 Racing</HashLink>
                    </li> 
                    </ul>
                </div>
              </Col>

              <Col xl={3} sm={6}>
                <div className="footer_nav">
                  <h4>Quick Links</h4>  
                  <ul>
                    <li>
                      <Link to={user ? '/refer-friends' : '/login'}>Invite Friends </Link>
                    </li>

                    <li>
                      <Link to={user ? '/Fantasy' : '/login'}>Fantasy Cricket </Link>
                    </li>
                    <li>
                      <HashLink to="/about-us">About Us </HashLink>
                    </li>

                    <li>
                      <Link to="/web-responsible-play">Helpdesk</Link>
                    </li> 
                  </ul> 

                </div>
              </Col>

              <Col xl={3} sm={6}>
                <div className="footer_nav">
                  <h4>Useful links</h4>  
                  <ul>
                    <li>
                      <Link to="/web-privacy-policy">Privacy Policy </Link>
                    </li>

                    <li>
                      <Link to="/web-terms-condition">Terms & Condition </Link>
                    </li>
                    <li>
                      <HashLink to="/home#FAQ">FAQs</HashLink>
                    </li>

                    <li>
                      <Link to="/web-responsible-play">Responsible Gaming </Link>
                    </li>

                    <li>
                      {/* <a href="#">Download App </a> */}
                      <HashLink to="/home#Download">Download App</HashLink>
                    </li>
                    <li>
                      <a href="#">How to Play </a>
                    </li>

                     
                  </ul>


                </div>
              </Col>

              <Col xl={3} sm={6} className="social_icons">
                <h4>Join the Conversation</h4>

                <a href="#" className="me-2">
                  <img src="../images/fb.svg" />
                </a>
                <a href="#" className="me-2">
                  <img src="../images/tw.svg" />
                </a>
                <a href="#" className="me-2">
                  <img src="../images/youtube.svg" />
                </a>
                <a href="#">
                  <img src="../images/insta.svg" />
                </a>
              </Col>
                </Row>

              </Col>
 
            </Row>
          </Container>
        </div>
        <div className="desclaimer">
          <Container>
            <div className="d-md-flex">
              <h6 className="text-white">DISCLAIMER:</h6>
              <p className="ms-md-3 mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
              </p>
            </div>
          </Container>
        </div>
      </footer>
    </>
  );
}

export default Footer;
