import React, { lazy } from 'react'

import Loadable from '../components/loader/Loadable'


const Soccer = Loadable(lazy(() => import('../pages/soccerGame/soccer/Soccer')))
const AllContestSoccer = Loadable(lazy(() => import('../pages/soccerGame/allContestSoccer/AllContestSoccer')))
const SoccerContestDetails = Loadable(lazy(() => import('../pages/soccerGame/soccerContestDetails/SoccerContestDetails')))
const SelectTeam = Loadable(lazy(() => import('../pages/soccerGame/soccer_teams/SoccerSelectTeam')))
const CreateTeam = Loadable(lazy(() => import('../pages/soccerGame/soccer_teams/CreateTeam')))
const SaveTeam = Loadable(lazy(() => import('../pages/soccerGame/soccer_teams/SaveTeam')))
const TeamPreview = Loadable(lazy(() => import('../pages/soccerGame/soccer_teams/TeamPreviewSoccer')))
const CreateSoccerContest = Loadable(lazy(() => import('../pages/soccerGame/allContestSoccer/CreateSoccerContest')))

const ContestPrizeBreakup = Loadable(lazy(() => import('../pages/soccerGame/allContestSoccer/PrizeBreakupSoccer')))
const PlayerInfo = Loadable(lazy(() => import('../pages/soccerGame/soccer_teams/Player_info_soccer')))
const UpcomingMatch = Loadable(lazy(() => import('../pages/soccerGame/FantasyMenu/UpcommingMatchsSoccer')))
const MyContest = Loadable(lazy(() => import('../pages/soccerGame/FantasyMenu/MyContest')))




const soccerRoutes = () => {
    return [
        {
            path: '/soccer',
            element: <Soccer />
        },
        {
            path: '/all-contest-soccer',
            element: <AllContestSoccer />
        },
        {
            path: '/soccer-contest-details',
            element: <SoccerContestDetails />
        },
        {
            path: '/soccer-select-team',
            element: <SelectTeam />
        },
        {
            path: '/soccer-create-team',
            element: <CreateTeam />
        },
        {
            path: '/soccer-save-team',
            element: <SaveTeam />
        },
        {
            path: '/soccer-team-preview',
            element: <TeamPreview />
        },
        {
            path: '/socceer-create-contest',
            element: <CreateSoccerContest />
        },
        {
            path: '/soccer-prize-breakup',
            element: <ContestPrizeBreakup />
        },
        {
            path: '/player-info-soccer',
            element: <PlayerInfo />
        },
        {
            path: '/upcomingmatch-soccer',
            element: <UpcomingMatch />
        },
        {
            path: '/mycontest-soccer',
            element: <MyContest />
        },

    ]
}

export default soccerRoutes