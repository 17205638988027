import { useRoutes, useNavigate, useLocation } from "react-router-dom";

// routes
import PrivateRoutes from "./PrivateRoutes";
import MainRoutes from "./MainRoutes";
import CommonRoutes from "./CommonRoutes";
import Session from "../utils/session";
import LeagueRoutes from "./LeagueRoutes";

// ==============================|| ROUTING RENDER ||============================== //
export default function CombineRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const Auth = Session.getSession("token") || false;
  // useEffect(() => {
  // //console.log('use effect called==>>>',Auth)
  //   // if (!Auth) {
  //   // //console.log('auth--::::::',Auth)
  //   //    navigate('/')
  //   // }
  //   // console.log('useEffect call and check auth routes======================>>>>>>>>>>>>>>',location?.pathname)
  // }, [location?.pathname]);
  if (!Auth) {
    location?.pathname === "/" && navigate("/home");
  }

  return useRoutes(
    [Auth ? PrivateRoutes : MainRoutes, CommonRoutes, LeagueRoutes],
    process.env.BASENAME
  );
}
