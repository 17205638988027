import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OPEN_SIDE_NAV } from '../redux/constants/Auth'
// import LOGO from '../../public/images/menu-left.png'
import LOGO from '../assets/images/menu-left.png'
import { useLocation } from 'react-router-dom'


const Profile = () => {
    const user_profile = useSelector(state => state?.auth?.user_profile)
    const dispatch = useDispatch()
    const location = useLocation()
    const { homeActiveKey } = useSelector(state => state.loader)

    const urls = {
        '/wallet': user_profile?.full_name,
        '/refer-friends': 'Refer & Earn',
        '/Fantasy': homeActiveKey === 'soccer' ? 'Football' : 'Cricket'
    }


    const infoShow = () => {
        dispatch({
            type: OPEN_SIDE_NAV,
            payload: true
        })
    }

    return (
        <div className="back_arrow">
            <div
                onClick={() => infoShow()}
                className="d-flex align-items-center position-relative cursor-pointer"
            >
                <div className="profile_pic">
                    {/* <img src={user_profile.image} className="me-3 " alt="" /> */}
                </div>
                <div className="sidmenu_icon" >
                    <img src={LOGO} className="me-3 " alt="" style={{ height: '20px', width: '22px' }} />
                </div>
                <span>{urls[location?.pathname]}</span>
            </div>
        </div>
    )
}

export default Profile