export const GET_ACTIVE_MATCHES_LIST = 'GET_ACTIVE_MATCHES_LIST'
export const GET_LIVE_AND_UPCOMING_MATCHES = 'GET_LIVE_AND_UPCOMING_MATCHES'
export const GET_BANNERS = 'GET_BANNERS'
export const JOINED_MATCHES_LIST = 'JOINED_MATCHES_LIST'


export const JOINED_CONTEST_LIST = 'JOINED_CONTEST_LIST'
export const PLAYER_TEAM_LIST = 'PLAYER_TEAM_LIST'
export const ALL_PLAYER_LIST = 'ALL_PLAYER_LIST'
export const TEAM_SCORE = 'TEAM_SCORE'
export const SERIES_PLAYER_LIST = 'SERIES_PLAYER_LIST'
export const LIVE_SCORE = 'LIVE_SCORE'
export const MATCH_TAB = 'MATCH_TAB'
export const PLAYER_DETAILS = 'PLAYER_DETAILS'
export const MY_CONTEST_TAB = 'MY_CONTEST_TAB'
export const CONTEST_DETAILS = 'CONTEST_DETAILS'
export const CONTEST_TEAM_PDF = 'CONTEST_TEAM_PDF'
export const CONTEST_ENTRY_FEE = 'CONTEST_ENTRY_FEE'
export const CONTEST_PRIZE_BREAKUP = 'CONTEST_PRIZE_BREAKUP'
export const CHECK_WALLET_BALANCE = 'CHECK_WALLET_BALANCE'
export const CONTEST_SHARE_CODE = 'CONTEST_SHARE_CODE'
export const ALL_CONTEST_TABS = 'ALL_CONTEST_TABS'
export const MATCH_SAVE_REMINDRS = 'MATCH_SAVE_REMINDRS'
export const PLAYER_SELECT_DESELECT = 'PLAYER_SELECT_DESELECT'
export const PLAYER_ROLE_TAB = 'PLAYER_ROLE_TAB'
export const CAPTAIN_AND_VICE_CAPTION = 'CAPTAIN_AND_VICE_CAPTION'













export const MATCH_DETAIL = 'MATCH_DETAIL'
export const GET_CONTEST_LIST_BYMATCH_JSON = 'GET_CONTEST_LIST_BYMATCH_JSON'





