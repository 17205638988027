import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import USDT from '../../../components/USDT';
import { join_contest_confirm } from '../../../redux/actions/SoccerActions';
import { CONTEST_CONFIRMATION_SOCCER } from '../../../redux/constants/soccer';
import { LOW_BALANCE } from '../../../redux/constants/Common';
import { Tooltip as ReactTooltip } from "react-tooltip";

const SoccerDialogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { low_balance } = useSelector((state) => state?.loader);
  const { checkWalletBalance, contest_confirmation_soccer, contestDetails } = useSelector((state) => state?.soccer);

  const handleClose = () => {
    dispatch({
      type: CONTEST_CONFIRMATION_SOCCER,
      payload: false,
    });
  };

  const closeBalancePopUp = () => {
    dispatch({
      type: LOW_BALANCE,
      payload: false
  })
  };

  const handleJoinContest = async () => {
    dispatch(join_contest_confirm(navigate, location))
  };


  return (
    <>
      {/* Contest join confirmation */}
      <Modal
        show={contest_confirmation_soccer}
        onHide={handleClose}
        className="contest_confirmation dark_theme_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>CONFIRMATION</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span style={{ fontSize: "12px", }}>
            {/* Remaining Balance (excluding Bonus) = <USDT /> */}
            Unutilized Balance + Winnings  = <USDT /> {" "}
            {Number(
              checkWalletBalance?.cash_balance +
              checkWalletBalance?.winning_balance
            )?.toLocaleString()}
          </span>
          <Table className="contest_confirmation_table mt-2">
            <tbody>
              <tr>
                <td>Entry</td>
                <td><USDT />{" "}{checkWalletBalance?.entry_fee}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "700" }}>
                  Usable Cash Bonus{" "}
                  <img
                    src="./images/info.png"
                    alt=""
                    id="bonus-tooltip"
                    style={{ maxWidth: "17px", maxHeight: "17px" }}
                  />
                </td>
                <td
                  className={
                    checkWalletBalance?.usable_bonus == 0 ? "" : "text-danger"
                  }
                >
                  <USDT /> {" "}
                  {checkWalletBalance?.usable_bonus == 0
                    ? Number(checkWalletBalance?.usable_bonus).toFixed(1)
                    : -Number(checkWalletBalance?.usable_bonus).toFixed(1)}
                </td>
              </tr>

              <tr className="total_value">
                <td>
                  <b>To Pay</b>
                </td>
                <td>
                  <b>
                    <USDT />{" "}
                    {Number(
                      checkWalletBalance?.entry_fee -
                      checkWalletBalance?.usable_bonus
                    ).toFixed(2)}
                  </b>
                </td>
              </tr>
            </tbody>
            <ReactTooltip
              anchorId={`bonus-tooltip`}
              place="bottom"
              variant="info"
              style={{ fontSize: "8px" }}
              content={() => (
                <>
                  Max 10% of total entry fee* per match *valid for selected
                  contests <br /> only *not valid for private contests
                </>
              )}
            />
          </Table>
          {/* <p>
            By Joining this contest you accept T&C and confirm, that you are not
            a resident of Assam, Odisha, Telangana or Sikkim{" "}
          </p> */}
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <Button
              variant="primary"
              onClick={() => handleJoinContest()}
              className="border-0 w-100"
            >
              Join Contest
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Contest join confirmation */}

      {/* low balancer confirmation */}
      <Modal show={low_balance} onHide={closeBalancePopUp} className="contest_confirmation contest_confirmation_popup ">
        <Modal.Header closeButton className='modal-header'>
          {/* <Modal.Title>CONFIRMATION</Modal.Title> */}
          <div className='modal-title'>
            <img src='../images/logo.png' alt='' height={'auto'} width={'130px'} />
          </div>
        </Modal.Header>


        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p className="text-white">Low balance! Please add <USDT /> {Math.ceil(contestDetails?.entryFee - (contestDetails?.cash_balance + contestDetails?.winning_balance))} to join contest</p>
        </Modal.Body>


        <Modal.Footer>
          <div className="w-100 confirm-box">
            <Button variant="secondary" onClick={() => closeBalancePopUp()} className="border-0 w-100 bg-orange" style={{ background: '#fb6d31' }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => closeBalancePopUp()} className="border-0 w-100 ms-10">
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SoccerDialogs