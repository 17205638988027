import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Offcanvas, Accordion, Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { OPEN_SIDE_NAV } from '../redux/constants/Auth'
import Session from '../utils/session';
import { useState } from 'react';
import { HOME_ACTIVE_KEY } from '../redux/constants/Common';

const BackArrow = ({ name }) => {
    const { homeActiveKey } = useSelector(state => state.loader)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()


    const handleBack = () => {
       
        if(location?.state?.from === "notification"){
            navigate('/Fantasy')
        }else if(location?.pathname === "/share-contest"){
            navigate('/Fantasy', {replace:true})
        }else{
            navigate(-1)
        }
        
       
    }

  


    var isUrl = ['/upcomingmatch-soccer', '/upcomingmatch']

    const handleUserTeam = (e, type) => {
        if(homeActiveKey === type){
            return
        }
        dispatch({ type: HOME_ACTIVE_KEY, payload: type })
        navigate(location?.pathname === '/upcomingmatch-soccer' ? '/upcomingmatch' : '/upcomingmatch-soccer')
    }


    return (
        <div className="back_arrow cursor">
            <div className="d-flex align-items-center">
                <div onClick={() => handleBack()}>
                    <img
                        src="./images/top_arrow.png"
                        className="me-3"
                        alt=""
                    />{" "}
                    {name}

                </div>
                {
                    isUrl?.includes(location?.pathname) &&
                    (
                        <div className="score-over-sec flex-fill mw-100 me-2 justify-content-end ">
                            <Dropdown className="highlight_tabs">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="bg-transparent border-0 p-0  text-dark">
                                    {"   "} &nbsp;
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="rounded-0 select_game_type" >
                                    <Dropdown.Item className={`text-black ${homeActiveKey === 'cricket' && 'background-grey'}`} onClick={(e) => handleUserTeam(e, 'cricket')}>Cricket</Dropdown.Item>
                                    <Dropdown.Item className={`text-black ${homeActiveKey === 'soccer' && 'background-grey'}`} onClick={(e) => handleUserTeam(e, 'soccer')}>Football</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default BackArrow