
export const LOADER = 'LOADER'
export const TEAM_LOADER = 'TEAM_LOADER'
export const INVITE_OPEN = 'INVITE_OPEN'
export const JOIN_CONTSEST ='JOIN_CONTSEST'
export const CONTEST_PDF_MODAL = 'CONTEST_PDF_MODAL'
export const CONTEST_CONFIRMATION = 'CONTEST_CONFIRMATION'
export const LOW_BALANCE = 'LOW_BALANCE'
export const HOME_ACTIVE_KEY = 'HOME_ACTIVE_KEY'









