import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import RightPanel from "../../components/RightPanel";
import { getStaticPage } from "../../redux/actions/OtherActions";
import parser from 'html-react-parser';

function WebAboutus() {
    const dispatch = useDispatch();

    const staticPage = useSelector(state => state?.static?.staticPage)
    //console.log('staticc==>>>>>>',staticPage)

    useEffect(() => {
        dispatch(getStaticPage('about-us'))
    }, [])


    const Content = () => {
        return (
            <div className="responsible_page" >
                <Container>       <div className="p-4 team_cols_list" >
                    <div className="text_wrapper"  >
                        <h5>{staticPage?.title}</h5>
                        <p> {staticPage?.content ? parser(staticPage?.content) : ''}</p>

                    </div>
                </div>
                </Container>
            </div>
        )
    }

    return (

        <>
            <Header />
            {/* <Banner />
          <Games />
          <OurGames />
          <Faq />
          <Download />
          <Offers /> */}
            <Content />
            <Footer />

        </>




    );
}

export default WebAboutus;
