
import Session from "../../utils/session";
import { CREATE_TRANS_ID } from "../constants/Payment";


let initialState = {
    paymentObj:{},
};



const PaymentReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case CREATE_TRANS_ID:
              //console.log('response in reducers===>>>>>',action.payload)
                return {
                    ...state,
                    paymentObj : action?.payload?.results
                };
           
            default:
                return state;
        }
    } catch (error) {
      //console.log('err in payment reducers==>>>>', error)
    }
};

export default PaymentReducer;