export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const VERIFY_OTP = 'VERIFY_OTP'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const LOGOUT = 'LOGOUT'
export const LOADER = 'LOADER'
export const GET_PROFILE = 'GET_PROFILE'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const EMAIL_VERIFICATION_LINK = 'EMAIL_VERIFICATION_LINK'
export const GET_STATE_LIST = 'GET_STATE_LIST'
export const PAN_UPDATE = 'PAN_UPDATE'
export const BANK_UPDATE = 'BANK_UPDATE'
export const ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT'
export const OPEN_SIDE_NAV = 'OPEN_SIDE_NAV'
export const WITHDRAW_LIST = 'WITHDRAW_LIST'
export const ADMIN_SETTING = 'ADMIN_SETTING'
export const LOAD_ACCOUNT_STATEMENT = 'LOAD_ACCOUNT_STATEMENT'







