import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import axios from 'axios'
import _ from 'lodash';
import Session from "../../utils/session";
import { LOADER, LOGIN_SUCCESS, LOGOUT_SUCCESS, PROFILE_UPDATE, GET_PROFILE, EMAIL_VERIFICATION_LINK, GET_STATE_LIST, PAN_UPDATE, BANK_UPDATE, ACCOUNT_STATEMENT, WITHDRAW_LIST, ADMIN_SETTING, LOAD_ACCOUNT_STATEMENT } from '../constants/Auth';
import { toast, ToastContainer } from "react-toastify";
var qs = require('qs');




export const loginUser = (data) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })

        let payload = {
            mobile: data?.mobile,
            country_code: '+91',
            referral_code:data?.referral_code
        }
        // payload = JSON.stringify(payload)


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.login, 'post', payload)

        if (res?.success) {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in login api::', error)
    }

}
export const verifyOtp = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let payload = {
            device_type: 'WEB',
            device_id: 'fU3eIZXpi0tRqpiMlmoyPD:APA91bG-YTzt55QlKq4BYVYjrfYPaado5GuD2xLL8za5uzfRelqsm7Yv-UimR_AWI1SpmGDi1bm0gEUCivqUlddCPAIPcJZtcXPCe0ZTWt8ttaas-WByIgjBWgp_GLttZd_mfH0OHKrc',
            otp: data?.otp,
            phone: data?.mobile,
            country_code: data?.country_code,
            email: '',
            referral_code: data?.referral_code
        }
        // payload = JSON.stringify(payload)


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.verifyOtp, 'post', payload)
      //console.log('resssssssssssssssss', res)
        if (res?.success) {
            if (res?.result?.dob && res?.result?.state ) {
                dispatch({ type: LOGIN_SUCCESS, payload: res })
            }
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in verify otp api::', error)
    }

}
export const logout = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.logout, 'get', payload)

        if (true) {
            Session.clearAllSession()
            dispatch({
                type: LOGOUT_SUCCESS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            window.location.reload();
            // window.history.pushState({urlPath:'/'},"",'/')
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }



    } catch (error) {
      //console.log('error in logout  api::', error)
    }

}
export const updateProfile = (dict) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })
        var data = {
            "email": dict?.email,
            "phone": dict?.mobile,
            "username": dict?.username?.trim(),
            "full_name": dict?.full_name?.trim(),
            'gender': dict?.gender,
            'dob': dict?.dob,
            'state': dict?.state,
            // 'gender': 'female',


        }
      

        data = JSON.stringify(dict)
        let formData = new FormData();

        formData.append('data', data)
        formData.append('profile_pic', dict?.image)


        const res = await helper.api(apiPath.updateProfile, 'post', formData)

        if (res?.success) {
            dispatch({
                type: PROFILE_UPDATE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in login api::', error)
    }

}
export const get_profile = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_profile, 'get', payload)

        if (res?.success) {
            dispatch({
                type: GET_PROFILE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in get_profile  api::', error)
    }

}
export const send_verify_email = (userData) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })

        var data = {
            "email": userData?.email
        }

        // data = JSON.stringify(data)

        const res = await helper.api(apiPath.send_verify_email, 'post', data)
        if (res?.success) {
            // dispatch({
            //     type: EMAIL_VERIFICATION_LINK,
            //     payload: res
            // })
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in send verify email api::', error)
    }
}
export const get_state_list = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}

        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_state_list, 'get', payload)

        if (res?.success) {
            dispatch({
                type: GET_STATE_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in get_state_list  api::', error)
    }

}
export const update_pan = (user) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })

        var data = {
            "pan_name": user?.pan_name,
            "pan_number": user?.pan,
            "date_of_birth": user?.dob,
            "state": user?.state,
        }
        data = JSON.stringify(data)
        let formData = new FormData();
        formData.append('data', data)
        formData.append('pan_pic', user?.image)
      //console.log("formData", formData)
        const res = await helper.api(apiPath.update_pan, 'post', formData)

        if (res?.success) {
            dispatch({
                type: PAN_UPDATE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in update pan api::', error)
    }

}
export const update_bank = (user) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })
        
        var data = {
            "account_holder_name": user?.account_holder_name,
            "account_no": user?.account_no,
            "ifsc_code": user?.ifsc_code,
            "bank_name": user?.bank_name,
            "branch": user?.branch_name,
        }
      //console.log("data=======>>>>>>>>>>>>>>", data)

        data = JSON.stringify(data)
        let formData = new FormData();
        formData.append('data', data)
        formData.append('bank_statement', user?.image)
      //console.log("formData", formData)
        const res = await helper.api(apiPath.update_bank, 'post', formData)

        if (res?.success) {
            dispatch({
                type: BANK_UPDATE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in update bank api::', error)
    }

}
export const get_account_statement = (qString, page) => async (dispatch) => {
    // console.log('dataaaaaaaaaa',page)
    try {
        // dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)
        let full_path = apiPath.account_statement + qString;

        payload = qs.stringify(payload);

        const res = await helper.api(full_path, 'GET', payload)
        // console.log("res", res);
        if (res?.success) {
            dispatch({
                type: ACCOUNT_STATEMENT,
                payload: { res, page }
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in account statement api::', error)
    }

}

export const load_account_statement = (qString) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)
        let full_path = apiPath.account_statement + qString;

        payload = qs.stringify(payload);

        const res = await helper.api(full_path, 'GET', payload)
      //console.log("res", res);
        if (res?.success) {
            dispatch({
                type: LOAD_ACCOUNT_STATEMENT,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in account statement api::', error)
    }

}
export const get_withdraw_list = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}



        const res = await helper.api(apiPath?.getWithdrawList, 'GET')
      //console.log("res", res);
        if (res?.success) {
            dispatch({
                type: WITHDRAW_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in account statement api::', error)
    }

}

export const bank_transafer = (request_amount) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = { request_amount: request_amount }


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath?.bankTransfer, 'post', payload)
      //console.log("res", res);
        if (res?.success) {

            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in account statement api::', error)
    }

}

export const get_setting = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })


        const res = await helper.api(apiPath?.getSetting, 'get')
      //console.log("res", res);
        if (res?.success) {

            dispatch({
                type: LOADER,
                payload: false
            })
            dispatch({
                type: ADMIN_SETTING,
                payload: res
            })

            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in account statement api::', error)
    }

}

export const updateTeam = (data) => async (dispatch) => {

    try {
      //console.log("update team action calledd==>>>>", data)
        dispatch({ type: LOADER, payload: true })
        const res = await helper.api(apiPath.createTeam, 'post', data)
      //console.log('result of api==>>>>>>>', res)

        if (res?.success) {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in update bank api::', error)
    }

}