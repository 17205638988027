


export const GET_STATIC_PAGE = 'GET_STATIC_PAGE'


export const GET_NOTIFICATION_PAGE = 'GET_NOTIFICATION_PAGE'
export const GET_NOTIFICATION_SCROLL_DATA = 'GET_NOTIFICATION_SCROLL_DATA'
export const CHECK_REFERRAL_CODE = 'CHECK_REFERRAL_CODE'


export const SET_REMINDER= 'SET_REMINDER'
export const TEAM_PREVIEW= 'TEAM_PREVIEW'





