import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import axios from 'axios'
import _ from 'lodash';
import Session from "../../utils/session";
import { LOADER, } from '../constants/Auth';
import { toast, ToastContainer } from "react-toastify";
import { GET_STATIC_PAGE, GET_NOTIFICATION_PAGE, GET_UPCOMING_NOTIFICATION, CHECK_REFERRAL_CODE, GET_NOTIFICATION_SCROLL_DATA } from '../constants/Other';
var qs = require('qs');




export const getStaticPage = (data) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })

        var path = apiPath.getStaticpage + data

        const res = await helper.api(path, 'get', {})

        if (res?.success) {
            dispatch({
                type: GET_STATIC_PAGE,
                payload: res?.result
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in get static page api::', error)
    }

}

export const getNotificationPage = (page) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })

        var path = apiPath.getNotification + `?page=${page ? page : 1}&itemsPerPage=30`

        const res = await helper.api(path, 'get', {})

        if (res?.success) {
            if(page > 1){
                dispatch({
                    type: GET_NOTIFICATION_SCROLL_DATA,
                    payload: res
                })
            }else{
                dispatch({
                    type: GET_NOTIFICATION_PAGE,
                    payload: res
                })
            }
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in get  notification page api::', error)
    }

}

export const readNotification = (data) => async (dispatch) => {

    try {

        // dispatch({ type: LOADER, payload: true })
        const res = await helper.api(apiPath.readNotification, 'post', { read: true })

    } catch (error) {
      //console.log('error in get  notification page api::', error)
    }

}


export const checkReferralCode = (referral_code) => async (dispatch) => {

    try {

        dispatch({ type: LOADER, payload: true })

        let payload = qs.stringify({ referral_code: referral_code });


        const res = await helper.api(apiPath.checkReferralCode, 'post', payload)

        if (res?.success) {
            dispatch({
                type: CHECK_REFERRAL_CODE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in get  notification page api::', error)
    }

}
