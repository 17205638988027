import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Offcanvas, Dropdown, Nav, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/AuthActions";
import { OPEN_SIDE_NAV } from "../redux/constants/Auth";
import { INVITE_OPEN } from "../redux/constants/Common";
import Session from "../utils/session";


const SideNav = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user_profile = useSelector(state => state.auth.user_profile)


    const logoutUser = async () => {
        const result = await dispatch(logout())
        if (result?.success) {
        dispatch({ type: OPEN_SIDE_NAV, payload: false })
            navigate('/')
        }

    }

    const myProfile = () => {
        navigate('/my-profile')
    }
    const hanldeNavOption = (nav) => {
        if (nav === '/withdrawamount') {
            (user_profile.emailverified && user_profile.bank_verified == 3 && user_profile.pan_verified == 3) ? navigate(nav) : navigate('/verifykyc')
        } else {

            if (nav === 'open') {
                dispatch({ type: INVITE_OPEN, payload: true })
            } else {
                navigate(nav)
            }
        }
        dispatch({ type: OPEN_SIDE_NAV, payload: false })

    }



    return (
        <Nav className="d-block">
            {/* <a onClick={() => hanldeNavOption('/my-profile')}>
                <Nav.Link to="/my-profile" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon1.svg"
                        alt=""
                    />
                    <h5>My profile </h5>
                </Nav.Link>

            </a> */}
            <a onClick={() => hanldeNavOption('/wallet')}>
                <Nav.Link to="/wallet" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon1.svg"
                        alt=""
                    />
                    <h5>My Account </h5>
                </Nav.Link>
            </a>
            {/* <a onClick={() => hanldeNavOption('/withdrawamount')}>
                <Nav.Link to="/withdrawamount" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon2.svg"
                        alt=""
                    />
                    <h5>Withdraw </h5>
                </Nav.Link>
            </a> */}
            <a onClick={() => hanldeNavOption('/transaction')}>
                <Nav.Link to="transaction" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon3.svg"
                        alt=""
                    />
                    <h5>My Transactions</h5>
                </Nav.Link>
            </a>
            <a onClick={() => hanldeNavOption('/MyOffer')}>
                <Nav.Link to="MyOffer" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon4.svg"
                        alt=""
                    />
                    <h5>Offers</h5>
                </Nav.Link>
            </a>
            <a onClick={() => hanldeNavOption('/rewards')}>

                <Nav.Link to="rewards" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon5.svg"
                        alt=""
                    />
                    <h5>Rewards</h5>
                </Nav.Link>
            </a>
            {/* <a onClick={() => hanldeNavOption('/profile')}>

                <Nav.Link to="" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon6.svg"
                        alt=""
                    />
                    <h5>Missions</h5>
                </Nav.Link>
            </a> */}
            {/* <a onClick={() => hanldeNavOption('/profile')}>
                <Nav.Link to="" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon7.svg"
                        alt=""
                    />
                    <h5>Responsible Play </h5>
                </Nav.Link>
            </a> */}
            <a onClick={() => hanldeNavOption('/howtoplay')}>
                <Nav.Link to="howtoplay" className="sidebar_content">
                    <img
                        className="me-2"
                        src="./images/canvas_icon8.svg"
                        alt=""
                    />
                    <h5>How to play</h5>
                </Nav.Link>
            </a>
            <Dropdown className="mb-0">
                <Dropdown.Toggle
                    id="dropdown-basic"
                    className="sidebar_content nav-link"
                >
                    <img
                        className="me-2"
                        src="./images/canvas_icon9.svg"
                        alt=""
                    />{" "}
                    <h5>More</h5>{" "}
                    <span className="angle">
                        <img src="./images/menu_arrow.png" />
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0 ">

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('/terms-condition')}>
                        Terms & Conditions
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('/privacypolicy')}>
                        Privacy Policy
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('/legality')}>
                    Legality 
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('open')}>

                        Contest Invite Code
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('/fantasypoint')}>
                        Fantasy Point system
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('/faqs')}>
                        FAQs
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={() => hanldeNavOption('/help')}>
                        Help
                    </Dropdown.Item>

                    <Dropdown.Item href="" onClick={logoutUser}>
                        Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Nav>
    )
}

export default SideNav