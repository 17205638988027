import React from "react";
import LeftPanel from "../components/LeftPanel";
import RightPanel from "../components/RightPanel";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import CommonBottom from "./CommonBottom";
import Header from "../pages/session_leguage/component/Header";
import Footer from "../pages/session_leguage/component/Footer";
import { Outlet } from "react-router-dom";

const LeagueLayout = () => {
  const loader = useSelector((state) => state?.loader?.loader);
  const team_loader = useSelector((state) => state?.loader?.team_loader);

  return (
    <>
      <div
        className={`main-wrapper-outer 
        //  ${loader || team_loader ? "loader" : ""}`}
      >
        {/* {(loader || team_loader) && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )} */}

        <div className="AL_body">
          <div className="AL_wrap2 h-100">
            <div className="d-flex h-100">
              <LeftPanel />

              <div className="middle_panel">
                <div className="middlePage_bg h-100">
                  <Header />

                  <div className="" id="">
                    <Outlet />
                  </div>

                  <Footer />
                </div>
              </div>

              <RightPanel />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeagueLayout;
