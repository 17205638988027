
import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from "react-router-dom";

import { ThreeCircles } from 'react-loader-spinner'
import { useEffect } from 'react';
import { LOADER } from '../redux/constants/Common';
import { Spinner } from "react-bootstrap";


const BaseLayout = () => {
  const dispatch = useDispatch()
  const loader = useSelector(state => state.loader.loader)
  const team_loader = useSelector(state => state?.loader?.team_loader)


  const style = {
    corners: 1,
    color: '#06bd53',
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: true,
    hwaccel: true,
    position: 'absolute',
  }

  useEffect(() => {
   setTimeout(() => {
    dispatch({type:LOADER,payload:false})
   }, 200);
  }, [])

  return (
    <>
      {/* <ThreeCircles
        wrapperStyle={style}
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={5}
        strokeWidthSecondary={2000}
        color="green"
        secondaryColor="red"
        visible={loader}
      /> */}
      {
       (loader || team_loader) && <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {/* <div style={{ opacity: loader && '0.3', pointerEvents: loader && 'none'}} className="main-wrapper-outer"> */}
      <div className={`main-wrapper-outer ${(loader || team_loader) ? "loader" : ""}`}>
        <Outlet />
      </div>
    </>
  );

};

export default BaseLayout;



