import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import axios from 'axios'
import _ from 'lodash';
import Session from "../../utils/session";
import { GET_ACTIVE_MATCHES_LIST, GET_LIVE_AND_UPCOMING_MATCHES, GET_BANNERS, JOINED_MATCHES_LIST, JOINED_CONTEST_LIST, TEAM_SCORE, SERIES_PLAYER_LIST, PLAYER_TEAM_LIST, LIVE_SCORE, PLAYER_DETAILS, CONTEST_DETAILS, CONTEST_TEAM_PDF, MATCH_DETAIL, GET_CONTEST_LIST_BYMATCH_JSON, ALL_PLAYER_LIST, CONTEST_PRIZE_BREAKUP, CONTEST_ENTRY_FEE, CHECK_WALLET_BALANCE, CONTEST_SHARE_CODE, MATCH_SAVE_REMINDRS } from '../constants/Fantasy';
import { toast, ToastContainer } from "react-toastify";
import { LOADER } from '../constants/Common';

var qs = require('qs');

export const get_active_matches_list = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_active_matches_list, 'get', payload)
        if (res?.success) {
            dispatch({
                type: GET_ACTIVE_MATCHES_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.success(res?.msg);
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_live_and_upcoming_matches = (data) => async (dispatch) => {
    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_live_and_upcoming_matches, 'get', payload)
        if (res?.success) {
            dispatch({
                type: GET_LIVE_AND_UPCOMING_MATCHES,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        }

    } catch (error) {
      //console.log('error in live and upcoming matches api::', error)
    }

}
export const get_banners = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = {}
        // payload = JSON.stringify(payload)


        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.get_banners + '?page=1&itemsPerPage=10', 'get', payload)
        if (res?.success) {
            dispatch({
                type: GET_BANNERS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        }

    } catch (error) {
      //console.log('error in Get banners api::', error)
    }

}
export const get_joined_matches_list = (data) => async (dispatch) => {

    
    try {
        dispatch({ type: LOADER, payload: true })
        let payload = qs.stringify({ status: data });
        const res = await helper.api(apiPath.joinedMatchesList, 'post', payload)
        if (res?.success) {
            dispatch({
                type: JOINED_MATCHES_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_joined_contest_list = (data) => async (dispatch) => {
    const user = Session.getSession('user')

    try {
        dispatch({ type: LOADER, payload: true })

      //console.log('match id===>>>>>>>>>>>',data?.match_id)

        let dict = { match_id: data?.match_id, series_id: data?.series_id, user_id: user?.id }
        let payload = qs.stringify(dict);

        const res = await helper.api(apiPath.joinedContestList, 'post', payload)
        
        if (res?.success) {
            dispatch({
                type: JOINED_CONTEST_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_team_score = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })


        // var axios = require('axios');
        // var data = {
        //     "match_id": 57072,
        //     "series_id": 125912
        // }


        let dict = { match_id: Number(data?.match_id), series_id: Number(data?.series_id) }
        // let payload = qs.stringify(data);


        const res = await helper.api(apiPath.teamScore, 'post', dict)
        if (res?.success) {
            dispatch({
                type: TEAM_SCORE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_player_team_list = (data, my_team_ids) => async (dispatch) => {

    // console.log('payload data', data)

    try {
        dispatch({ type: LOADER, payload: true })
        // console.log(data);

        let dict = { match_id: data?.match_id, series_id: data?.series_id, team_id: my_team_ids || '' }
        let payload = qs.stringify(dict);

        const res = await helper.api(apiPath.playerTeamList, 'post', payload)
        if (res?.success) {
            dispatch({
                type: PLAYER_TEAM_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_series_player_list = (data, is_player_state) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let dict = { match_id: data?.match_id, series_id: data?.series_id }
        if(is_player_state){
            dict.is_player_state = 1
        }
        let payload = qs.stringify(dict);

        const res = await helper.api(apiPath.seriesPlayerList, 'post', payload)
        if (res?.success) {
            dispatch({
                type: SERIES_PLAYER_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_live_score = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let dict = { "match_id": parseInt(data?.match_id), "series_id": parseInt(data?.series_id) }
        // let payload = qs.stringify(dict);

        const res = await helper.api(apiPath.liveScore, 'post', dict)
        if (res?.success) {
            dispatch({
                type: LIVE_SCORE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_player_details = (data) => async (dispatch) => {
  //console.log('Player info APi Calleed')
    try {
        dispatch({ type: LOADER, payload: true })
        const playerData = Session.getSession('player_payload')
        let dict = { match_id: Number(data?.match_id), series_id: Number(data?.series_id), player_id: Number(data?.player_id) }


        const res = await helper.api(apiPath.getPlayerDetails, 'post', dict)
        if (res?.success) {
            dispatch({
                type: PLAYER_DETAILS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
          //console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',res)
            return res
            // console.log(res?.results?.length > 0 ? true : false)
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_contest_details = (data) => async (dispatch) => {
    const user = Session.getSession('user')

    try {
        dispatch({ type: LOADER, payload: true })
        let dict = { match_id: Number(data?.match_id), series_id: Number(data?.series_id), contest_id: data?.contest_id, user_id: user?.id }


        const res = await helper.api(apiPath.getContestDetails, 'post', dict)
        if (res?.success) {
            dispatch({
                type: CONTEST_DETAILS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const contest_team_pdf = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let dict = { match_id: Number(data?.match_id), series_id: Number(data?.series_id), contest_id: data?.contest_id, winning_team: data?.winning_team }


        const res = await helper.api(apiPath.contestTeamPDF, 'post', data)
        if (res?.success) {
            dispatch({
                type: CONTEST_TEAM_PDF,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}
export const get_match_detail = (data) => async (dispatch, getState) => {
    try {
        dispatch({ type: LOADER, payload: true })

      //console.log("data", data)
        let dict = { match_id: Number(data?.match_id) }



        const res = await helper.api(apiPath.getMatchDetail, 'post', dict)
        if (res?.success) {
            dispatch({
                type: MATCH_DETAIL,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in match detail api::', error)
    }

}
export const get_contest_list_byMatch_json = (data) => async (dispatch, getState) => {
    try {
        dispatch({ type: LOADER, payload: true })
        const user = Session.getSession('user')

        let dict = { match_id: Number(data?.match_id), series_id: Number(data?.series_id), user_id: user?.id, filter_data: data?.filter_data }



        const res = await helper.api(apiPath.getContestListByMatchJson, 'post', dict)
        if (res?.success) {
            dispatch({
                type: GET_CONTEST_LIST_BYMATCH_JSON,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in match detail api::', error)
    }

}


//get all player list
export const get_all_player_list = (data) => async (dispatch) => {

    // console.log('payload data', data)

    try {
        dispatch({ type: LOADER, payload: true })

        let dict = { match_id: data?.match_id, visitor_team_id: data?.visitor_team_id, local_team_id: data?.local_team_id, series_id: data?.series_id }
        let payload = qs.stringify(dict);

        const res = await helper.api(apiPath.allPlayerList, 'post', payload)
      //console.log('result====>>>>>??>>>>>>>>>>', res)
        if (res?.success) {
            dispatch({
                type: ALL_PLAYER_LIST,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: ALL_PLAYER_LIST,
                payload: []
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}


export const get_contest_entry_fee = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let dict = { contest_size: data?.contest_size, winning_amount: data?.winning_amount, }
        let payload = qs.stringify(dict);

        const res = await helper.api(apiPath.getEntryFee, 'post', payload)
      //console.log('result====>>>>>??>>>>>>>>>>', res)
        if (res?.success) {
            dispatch({
                type: CONTEST_ENTRY_FEE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}

export const contest_prize_breakup = (contest_size) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let dict = { contest_size: parseInt(contest_size) }
        // let payload = qs.stringify({ contest_size: 100 });
        // let payload = { contest_size: 100 }


        const res = await helper.api(apiPath.contestPrizeBreakup, 'post', dict)
      //console.log('result====>>>>>??>>>>>>>>>>', res)
        if (res?.success) {
            dispatch({
                type: CONTEST_PRIZE_BREAKUP,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}

export const user_create_contest = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        // let dict = { contest_size: parseInt(contest_size) }
        let payload = qs.stringify(data);
        // let payload = { contest_size: 100 }


        const res = await helper.api(apiPath.userCreateContest, 'post', payload)

        if (res?.success) {
            dispatch({
                type: CONTEST_SHARE_CODE,
                payload: res
            })
            Session.setSession('contestInviteCode', res?.results?.invite_code)
            dispatch({
                type: LOADER,
                payload: false
            })

            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}

export const join_contest_check_wallet = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let dict = {
            match_id: data?.match_id,
            series_id: data?.series_id,
            contest_id: data?.contest_id,
            entry_fees: data?.entryFee
        }

        let payload = qs.stringify(dict);



        const res = await helper.api(apiPath.joinContestCheckWallet, 'post', payload)

        if (res?.success) {
            dispatch({
                type: CHECK_WALLET_BALANCE,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}

export const apply_contest_invite_code = (invite_code) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })



        let payload = qs.stringify({ invite_code: invite_code });



        const res = await helper.api(apiPath.applyContestInviteCode, 'post', payload)

        if (res?.success) {

            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}

export const join_contest = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let payload = qs.stringify(data);
        const res = await helper.api(apiPath.joinContest, 'post', payload)

        if (res?.success) {

            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }
}

export const save_reminders = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        let payload = qs.stringify(data);
        const res = await helper.api(apiPath.matchSaveRemindrs, 'post', payload)
      //console.log(res, "save reminder what get====>>>>>>>>>>", res)

        if (res?.success) {

            dispatch({
                type: LOADER,
                payload: false
            })

            return res
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return false
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}

export const get_player_state_for_single_match = (data) => async (dispatch) => {
  //console.log('Player info APi Calleed')
    try {
        dispatch({ type: LOADER, payload: true })
        const playerData = Session.getSession('player_payload')
        let dict = { match_id: Number(data?.match_id), series_id: Number(data?.series_id), player_id: Number(data?.player_id) }


        const res = await helper.api(apiPath.getPlayerStateForSingleMatch, 'post', dict)
        if (res?.success) {
            dispatch({
                type: PLAYER_DETAILS,
                payload: res
            })
            dispatch({
                type: LOADER,
                payload: false
            })
          //console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',res)
            return res
            // console.log(res?.results?.length > 0 ? true : false)
        } else {
            dispatch({
                type: LOADER,
                payload: false
            })
            // toast.error(res?.msg);
            return res
        }

    } catch (error) {
      //console.log('error in upcoming matches api::', error)
    }

}