
import React, { useEffect, useState } from 'react'
import CombineRoutes from './routes/CombineRoutes'
import { ToastContainer } from 'react-toastify'

import 'react-tooltip/dist/react-tooltip.css'
import 'slick-carousel/slick/slick.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/afterlogin/style.css'
import './assets/css/afterlogin/responsive.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './App.css'

function App () {
// console.log('socket url==========>>>>>>>>>>>>>>>>>>',process.env.REACT_APP_SOCKET_URL);
  return (
    <div className='App'>
      <CombineRoutes />
      <ToastContainer icon={false} />
    </div>
  )
}

export default App
