import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Session from "../utils/session";

function BottomMenu() {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const homeActiveKey = Session.getSession("homeActiveKey");
  // const path = Session.getSession('activeMenu')
  // console.log('----------------------',path);

  // var path =  url : Session.getSession('activeMenu')

  const handleNavigate = (nav) => {
    navigate(nav);
    // Session.setSession('activeMenu', nav)
    if (nav === "/upcomingmatch") {
      Session.clearSession("selected_tab");
    }
  };

  const urls = {
    "/Fantasy": ["/Fantasy", "/FantasyMatch"],
    "/MyOffer": ["/MyOffer"],
    "/upcomingmatch": ["/upcomingmatch"],
    "/refer-friends": ["/refer-friends"],
    "/wallet": ["/wallet"],
  };

  const activeMenu = (nav) => {
    if (urls[nav].includes(url)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="bottom_menu menu_tabs_fx">
        <ul>
          <li className="cursor text-yellow">
            <a
              onClick={() => handleNavigate("/Fantasy")}
              className={`${activeMenu("/Fantasy") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/home_active.png"
                alt=""
              />{" "}
              Home
            </a>
          </li>
          <li className="cursor text-light-blue">
            <a
              onClick={() => handleNavigate("/MyOffer")}
              className={`${activeMenu("/MyOffer") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/offer_active.png"
                alt=""
              />{" "}
              Offers
            </a>
          </li>
          <li className="home_menu cursor bottom-home">
            <a
              onClick={() =>
                handleNavigate(
                  homeActiveKey === "soccer"
                    ? "upcomingmatch-soccer"
                    : "/upcomingmatch"
                )
              }
              className={`${activeMenu("/upcomingmatch") ? "active" : ""}`}
            >
              <img className="d-block" src="./images/home_menu.png" alt="" />
              My Fantasy
            </a>
          </li>
          <li className="cursor text-purple">
            <a
              onClick={() => handleNavigate("/refer-friends")}
              className={`${activeMenu("/refer-friends") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/refer_active.png"
                alt=""
              />{" "}
              Refer & Earn
            </a>
          </li>
          <li className="cursor text-light-red">
            <a
              onClick={() => handleNavigate("/wallet")}
              className={`${activeMenu("/wallet") ? "active" : ""}`}
            >
              {" "}
              <img
                className="active"
                src="./images/wallet_active.png"
                alt=""
              />{" "}
              Wallet
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BottomMenu;
