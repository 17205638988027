import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Session from '../utils/session'
import BackArrow from './BackArrow'
import Profile from './Profile'
import { useState } from 'react'
import { useEffect } from 'react'

const CommonHeader = () => {
    const location = useLocation()
    const user = Session?.getSession('user')
    const { allContestTabs } = useSelector(state => state?.match)
    const { teamType, playerTeamCount } = useSelector(state => state?.soccerTeam)
    const playerTeamCountCricket = useSelector(state => state?.match?.all_player_list?.playerTeamCount)

    const [typeTemOpration, setTypeTemOpration] = useState('')



    useEffect(() => {
      
        if(location?.pathname === '/update-team' && playerTeamCountCricket || playerTeamCountCricket == '0'){
            var teamNameType = `Edit Team ${playerTeamCountCricket}`
            setTypeTemOpration(teamNameType)
        }
        if(location?.pathname === '/create-team' && playerTeamCountCricket || playerTeamCountCricket == '0'){
            var teamNameType = `Create Team ${playerTeamCountCricket + 1}`
            setTypeTemOpration(teamNameType)
        }
    }, [location?.pathname, playerTeamCountCricket])
    

    
    // const { homeActiveKey } = useSelector(state => state.loader)

   


    var dict = {
        '/MyOffer': 'My Offers',
        '/upcomingmatch': 'Cricket',
        '/FantasyMatch': 'Fantasy',
        '/AllContest': `${allContestTabs === 'allContest' ? 'Contests' : allContestTabs === 'my_contest' ? "My Contest" : "My Teams"}`,
        // '/AllContest': inner[allContestTabs],
        '/contest-details': 'Cricket',
        '/update-team': `${typeTemOpration}`,
        '/save-team': `${typeTemOpration}`,
        '/upcomingmatch': user?.username,
        '/mycontest': 'Cricket',
        '/player-info': 'Player Info',
        '/addcash': 'Add Cash',
        '/transaction': 'My Transactions',
        '/rewards': 'Rewards',
        '/howtoplay': 'How To Play',
        '/terms-condition': 'Terms & Conditions',
        '/privacypolicy': 'Privacy Policy',
        '/legality': 'Legality',
        '/fantasypoint': 'Fantasy Point..',
        '/faqs': 'FAQs',
        '/my-profile': 'My Profile De...',
        '/profile': 'Welcome!',
        '/notifications': 'Notifications',
        '/verifykyc': 'Verify KYC',
        '/prize-breakup': 'Create Contest',
        '/createcontest': 'Create Contest',
        '/create-team': `Create Team ${playerTeamCountCricket + 1}`,
        '/withdrawamount': 'Withdraw Amount',
        '/select-team': 'Select Team',
        '/upcomingmatch': 'Cricket',
        '/all-contest-soccer': 'Contests',
        '/soccer-contest-details': 'Leaderboard',
        '/soccer-select-team': 'Select Team',
        '/soccer-create-team': `${teamType}  ${teamType === 'Create Team' ? playerTeamCount + 1 : playerTeamCount}`,
        '/soccer-save-team':  `${teamType}  ${teamType === 'Create Team' ? playerTeamCount + 1 : playerTeamCount}`,
        "/socceer-create-contest": "Create Contest",
        "/soccer-prize-breakup": "Create Contest",
        "/share-contest": " Invite Your F...",
        "/player-info-soccer": 'Player Info',
        "/upcomingmatch-soccer": 'Football',
        "/mycontest-soccer": 'Football',
    }

    return (
        <>
            {dict.hasOwnProperty(location?.pathname) ?
                <BackArrow name={dict[location?.pathname]} />
                :
                <Profile />
            }
        </>
    )
}

export default CommonHeader